import React from 'react'
import LayoutBs from '../../components/LayoutBs'
import Header from '../../components/Header/header'
import Intro from '../../components/Intro/intro'
import About from '../../components/About/about'
import Brands from '../../components/Brands/brands'
import Promoting from '../../components/Promoting/promoting'
import Testimonials from '../../components/Testimonials/testimonials'
import PartnersSlider from '../../components/Partners/partners'
import Footer from '../../components/Footer/footer'
import "animate.css/animate.min.css";
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import {latest_projects, more_projects, see_more, blog} from '../../components/strings'
import Helmet from 'react-helmet'
import AOS from 'aos';
import image from '../../../static/images/well_prmotion_marketing_agency.jpg'
class IndexPageBs extends React.Component{
  componentDidMount(){
    AOS.init({
      duration : 500
    })
  }
  render(){
    const prefix_string = "bs";
    const blog_posts = get(this, 'props.data.blog.edges')
    const portfolio_posts = get(this, 'props.data.portfolio.edges')
    return(
  <LayoutBs>

      <Helmet
          title={"Web dizajn, SEO, oglasi za Google i društvene mreže, konzalting"}
          meta={[
            { name: 'description', content: 'WELL - Agencija za digitalni marketing | Dizajn i izrada profesionalnih web stranica, SEO, Google oglašavanje, društveni mediji, konzalting' },
            {name: 'og:image', content: "https://www.wellpromotion.ba/images/well_prmotion_marketing_agency.jpg" },
            {name: 'og:image:type', content: "image/jpg" },
            {name: 'og:type', content: "website" },
            {name: 'og:url', content: "https://wellpromotion.ba"},
            {name: 'twitter:card', content: "https://www.wellpromotion.ba/images/well_prmotion_marketing_agency.jpg" },
            {name: 'twitter:image:alt', content: "Well Business Promotion Marketing Agency" },
          ]} >
        </Helmet>

    <div id="home">
  <Header prefix_string_page={prefix_string}/>
  <Intro prefix_string_page={prefix_string}/>
  <section id="nase-usluge" className="section default our-services">
    <About  prefix_string_page={prefix_string}/>
  </section>
  <Brands prefix_string_page={prefix_string}/>
  <Promoting prefix_string_page={prefix_string}/>

  <section id="s_projects" className="section default">
    <div className="overlay_image"></div>
     <div className="left-overlay"></div>
            <div data-aos='fade-zoom-in' className="container">
            <div data-aos='fade-zoom-in' className="title-box">
                        <div className="first">
                            <h2 className="title"><span className="section-number">03</span>{latest_projects}</h2>
                        </div>
                    </div>
           
            <div className="panel columns is-multiline">
                    {portfolio_posts.map(({ node }) => {
            
              return( 
                <Link
                to={node.fields.slug}
                className="column is-4 single-product">
              <div data-aos='fade-zoom-in'>
              <div className="hover_overlay"></div>
              <div className="product-image"><img src={node.frontmatter.image} alt={node.frontmatter.meta} title={node.frontmatter.meta}/></div>
              {node.frontmatter.contentType}
              <div className="product-info">
                  <div className="left">
                      <h3 className="category">{node.frontmatter.category}</h3>
                      <h4 className="product-title">{node.frontmatter.title}</h4>
                  </div>
                  <div className="right">
                      <span className="read-more"></span>
                  </div>
              </div>  
              </div>
            </Link>
                    )  })} 
                </div>
            <div className="button-area"><Link to={prefix_string + "/portfolio/web-dizajn"} className="button black center" title="Pogledaj više projekata iz kategorija digitalni marketing, programiranje, web i grafički dizajn">{more_projects}<span class="icon arrow"></span></Link></div>   
            </div>    
    </section>
  <Testimonials prefix_string_page={prefix_string}/>

  <section id="s_projects" className="section default">
    <div className="overlay_image"></div>
     <div className="left-overlay"></div>
            <div data-aos='fade-zoom-in' className="container">
            <div data-aos='fade-zoom-in' className="title-box">
                        <div className="first">
                            <h2 className="title"><span className="section-number">05</span>{blog}</h2>
                        </div>
                    </div>
           
            <div className="panel columns is-multiline">
                    {blog_posts.map(({ node }) => {
            
              return( 
                <Link
                to={node.fields.slug}
                className="column is-4 single-product">
              <div data-aos='fade-zoom-in'>
              <div className="hover_overlay"></div>
              <div className="product-image"><img src={node.frontmatter.image} alt={node.frontmatter.meta} title={node.frontmatter.meta}/></div>
              {node.frontmatter.contentType}
              <div className="product-info">
                  <div className="left">
                      <h3 className="category">{node.frontmatter.category}</h3>
                      <h4 className="product-title">{node.frontmatter.title}</h4>
                  </div>
                  <div className="right">
                      <span className="read-more"></span>
                  </div>
              </div>  
              </div>
            </Link>
                    )  })} 
                </div>
            <div className="button-area"><Link to={prefix_string + "/blog"} className="button black center" title="Pogledaj više novosti o digitalnom marketingu, programiranju, webu i grafičkom dizajnu">{see_more}<span class="icon arrow"></span></Link></div>   
            </div>    
    </section>
    <PartnersSlider  prefix_string_page={prefix_string}/>
  <Footer prefix_string_page={prefix_string}/>
  </div>
</LayoutBs>
)
    }
  }

export default IndexPageBs

export const indexBsPageQuery = graphql`
  query
  {
			portfolio: allMarkdownRemark(
				filter: { frontmatter: { templateKey: { eq: "bs_portfolio" } } },
				sort: { fields: [frontmatter___sorting_date], order: DESC },
        limit: 3, skip: 0){
				edges {
          node {
            excerpt
            id
            fields {
              slug
            }
            frontmatter {
                title
                client
                category
                date
                sorting_date
                website
                website_url
                description
                meta
                image
            }
          }
        }
			}

      blog: allMarkdownRemark(
				filter: { frontmatter: { templateKey: { eq: "bs_blog" } } },
				sort: { fields: [frontmatter___sorting_date], order: ASC },
        limit: 3, skip: 0){
				edges {
          node {
            excerpt
            id
            fields {
              slug
            }
            frontmatter {
                title
                client
                category
                date
                sorting_date
                website
                website_url
                description
                meta
                image
            }
          }
        }
			}

		}
`